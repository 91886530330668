export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CLEAR = "AUTH_CLEAR";
export const AUTH_REFRESH = "AUTH_REFRESH";
export const AUTH_CHANGE_PASS = "AUTH_CHANGE_PASS";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_DISABLED = "AUTH_DISABLED";
export const AUTH_ENABLED = "AUTH_ENABLED";
