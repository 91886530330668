var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-12 pb-8",attrs:{"color":"foreground"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("User Settings")]),_c('v-spacer')],1),(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-card-text',[_vm._v(" This is where you can change settings related to your user account. ")]),_c('h2',{staticClass:"font-weight-bold ml-5"},[_vm._v(" API Keys "),_c('v-dialog',{attrs:{"id":"keyModal","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.keyDialog),callback:function ($$v) {_vm.keyDialog=$$v},expression:"keyDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary"},[_vm._v(" Generate API Key ")]),_c('v-card-text',[_vm._v(" API Keys should be treated as a password and should only be provided to applications you trust. Once this box is closed you will be unable to retrive this key so be sure to copy it and test your application first. "),_c('br'),_c('v-form',[(!_vm.newKey)?_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.keyForm.key_name),callback:function ($$v) {_vm.$set(_vm.keyForm, "key_name", $$v)},expression:"keyForm.key_name"}}):_vm._e()],1),(!_vm.newKey)?_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.generate_api_key()}}},[_vm._v(" Generate Key")]):_vm._e(),(_vm.newKey)?_c('br'):_vm._e(),(_vm.newKey)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" Generated API Key:")]):_vm._e(),(_vm.newKey)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.copykey();
              _vm.saved = true;}}},[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1):_vm._e(),(_vm.newKey)?_c('v-textarea',{attrs:{"shaped":"","outlined":"","dense":"","readonly":"","no-resize":"","id":"newapikey"},on:{"click":function($event){_vm.copykey();
              _vm.saved = true;}},model:{value:(_vm.newKey),callback:function ($$v) {_vm.newKey=$$v},expression:"newKey"}}):_vm._e(),_c('v-snackbar',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.saved = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.saved),callback:function ($$v) {_vm.saved=$$v},expression:"saved"}},[_vm._v(" Copied to clipboard ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.keyDialog = false;
              _vm.newKey = '';
              _vm.keyForm.key_name = '';}}},[_vm._v(" Close ")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.apiKeys,"items-per-page":5},scopedSlots:_vm._u([{key:"item.key_name",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.revoke_api_key(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.key_name)+" ")])]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"CreatedAt"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }