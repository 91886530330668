var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"apps-list component",staticStyle:{"max-width":"90%"}},[_c('v-card',{attrs:{"color":"foreground"}},[_c('v-fade-transition',[(_vm.isLoading && _vm.isLoadingValue === 0)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","bottom":""}}):_vm._e()],1),_c('v-fade-transition',[(_vm.isLoadingValue > 0)?_c('v-progress-linear',{attrs:{"color":"primary","bottom":""},model:{value:(_vm.isLoadingValue),callback:function ($$v) {_vm.isLoadingValue=$$v},expression:"isLoadingValue"}}):_vm._e()],1),_c('v-card-title',{staticClass:"primary font-weight-bold"},[_vm._v(" Apps "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","to":"/apps/deploy"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","color":"secondary","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-title',{attrs:{"color":"secondary"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.checkUpdate(_vm.apps)}}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Updates")]):_vm._e(),_c('v-icon',[_vm._v("mdi-update")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.refresh()}}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Refresh")]):_vm._e(),_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_vm._v("Columns")]):_vm._e(),_c('v-icon',[_vm._v("mdi-border-all")])],1)]}}])},[_c('v-list',{attrs:{"color":"foreground"}},_vm._l((_vm.headers),function(item,index){return _c('v-list-item',{key:index,attrs:{"color":"primary"}},[_c('v-checkbox',{attrs:{"label":item.text,"value":item,"multiple":""},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1)}),1)],1)],1),(_vm.action)?_c('v-card-subtitle',[_vm._v(_vm._s(_vm.action)+" ")]):_vm._e(),_c('v-data-table',{staticClass:"mx-auto foreground",staticStyle:{"width":"99%"},attrs:{"headers":_vm.selectedHeaders,"items":_vm.apps,"items-per-page":25,"footer-props":{
        'items-per-page-options': [15, 25, 50, -1]
      },"search":_vm.search,"single-select":""},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"namecell"},[_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":true,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","size":"small"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"color":"foreground","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editClick({ Name: item.name })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-edit-outline")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1),(!item.Config.Image.includes('selfhostedpro/yacht'))?_c('v-list-item',{on:{"click":function($event){return _vm.Update(item.name)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-update")])],1),_c('v-list-item-title',[_vm._v("Update")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.AppAction({ Name: item.name, Action: 'start' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-play")])],1),_c('v-list-item-title',[_vm._v("Start")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.AppAction({ Name: item.name, Action: 'stop' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-stop")])],1),_c('v-list-item-title',[_vm._v("Stop")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.AppAction({ Name: item.name, Action: 'restart' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-list-item-title',[_vm._v("Restart")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.AppAction({ Name: item.name, Action: 'kill' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-fire")])],1),_c('v-list-item-title',[_vm._v("Kill")])],1),_c('v-list-item',{on:{"click":function($event){_vm.selectedApp = item;
                  _vm.removeDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Remove")])],1)],1)],1),(_vm.selectedApp)?_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Remove "+_vm._s(_vm.selectedApp.name)+"? ")]),_c('v-card-text',[_vm._v(" Are you sure you want to permanently remove "+_vm._s(_vm.selectedApp.name)+"?"),_c('br'),_vm._v(" All non peristent data will be removed. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.AppAction({ Name: _vm.selectedApp.name, Action: 'remove' });
                    _vm.removeDialog = false;}}},[_vm._v(" Remove ")])],1)],1)],1):_vm._e(),_c('span',{staticClass:"nametext ml-1"},[_vm._v(_vm._s(item.name))]),(item.isUpdatable)?_c('v-tooltip',{staticClass:"mb-2",attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"color":"primary","size":"6"}},'v-avatar',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Update Available")])]):_vm._e()],1)]}},{key:"item.project",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"projectcell"},[_c('span',[_vm._v(_vm._s(item.Config.Labels["com.docker.compose.project"] || "-")+" ")])])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"statuscell"},[_c('span',[_vm._v(_vm._s(item.State.Status)+" ")])])]}},{key:"item.ports",fn:function(ref){
                    var item = ref.item;
return _vm._l((_vm.convPorts(item.ports)),function(port,index){return _c('ins',{key:index,staticStyle:{"text-decoration":"none"}},[_c('v-tooltip',{attrs:{"top":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(port.hip == '0.0.0.0')?_c('v-chip',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(port.hip != '::'),expression:"port.hip != '::'"}],staticClass:"mx-1",staticStyle:{"text-decoration":"none"},attrs:{"color":"primary","label":"","small":"","href":'http://' + _vm.host_ip + ':' + port.hport,"target":"_blank"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-link-variant")]),_vm._v(_vm._s(item.Config.Labels[("local.yacht.port." + (port.hport))] || port.hport))],1):_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1",staticStyle:{"text-decoration":"none"},attrs:{"color":"primary","label":"","small":"","href":'http://' + port.hip + ':' + port.hport,"target":"_blank"}},'v-chip',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-link-variant")]),_vm._v(_vm._s(item.Config.Labels[("local.yacht.port." + (port.hport))] || port.hport))],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(port.hport)+" ")])])],1)})}},{key:"item.image",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"ImageName"},[_vm._v(_vm._s(item.Config.Image))])]}},{key:"item.created",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"CreatedAt"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.Created))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }