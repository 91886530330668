var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"volumes-list component",staticStyle:{"max-width":"90%"}},[_c('v-card',{attrs:{"color":"foreground"}},[_c('v-fade-transition',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","bottom":""}}):_vm._e()],1),_c('v-card-title',{staticClass:"primary font-weight-bold"},[_vm._v(" Volumes "),_c('v-dialog',{attrs:{"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('v-card',{attrs:{"color":"foreground"}},[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Create Volume ")]),_c('v-card-text',[_vm._v(" Create a Volume. ")]),_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Volume","placeholder":"yacht_data","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.submit();
                _vm.createDialog = false;}}},[_vm._v(" Create ")])],1)],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mx-auto volume-datatable foreground",staticStyle:{"max-width":"99%"},attrs:{"headers":_vm.headers,"items":_vm.volumes,"items-per-page":25,"footer-props":{
        'items-per-page-options': [15, 25, 50, -1]
      },"search":_vm.search},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"align-streatch text-truncate nametext mt-2"},[_vm._v(_vm._s(item.Name))]),_c('v-spacer'),(item.inUse == false)?_c('v-chip',{staticClass:"align-center mt-1",attrs:{"outlined":"","small":"","color":"orange lighten-1","label":""}},[_vm._v("Unused")]):_vm._e(),_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-streatch",attrs:{"icon":"","size":"small"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"color":"foreground","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.volumeDetails(item.Name)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("View")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.selectedVolume = item;
                  _vm.deleteDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]}},{key:"item.Project",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"projectcell"},[(item.Labels)?_c('span',{staticClass:"d-inline-block text-truncate idtext"},[_vm._v(" "+_vm._s(item.Labels["com.docker.compose.project"] || "-")+" ")]):_vm._e()])]}},{key:"item.Driver",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"idcell"},[_c('span',{staticClass:"d-inline-block text-truncate idtext"},[_vm._v(" "+_vm._s(item.Driver)+" ")])])]}},{key:"item.CreatedAt",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate flex-grow-1 flex-shrink-0"},[_vm._v(_vm._s(_vm._f("formatDate")(item.CreatedAt)))])]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" No Volumes available. ")])])],2)],1),(_vm.selectedVolume)?_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Delete the volume? ")]),_c('v-card-text',[_vm._v(" Are you sure you want to permanently delete the volume?"),_c('br'),_vm._v(" This action cannot be revoked. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.deleteVolume(_vm.selectedVolume.Name);
            _vm.deleteDialog = false;}}},[_vm._v(" Delete ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }