var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"networks-list component",staticStyle:{"max-width":"90%"}},[_c('v-card',{attrs:{"color":"foreground"}},[_c('v-fade-transition',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","bottom":""}}):_vm._e()],1),_c('v-card-title',{staticClass:"primary font-weight-bold"},[_vm._v(" Networks "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","to":{ path: "/resources/networks/new" }}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mx-auto network-datatable foreground",staticStyle:{"max-width":"99%"},attrs:{"headers":_vm.headers,"items":_vm.networks,"items-per-page":25,"footer-props":{
        'items-per-page-options': [15, 25, 50, -1]
      },"search":_vm.search},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.Name",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"align-streatch text-truncate nametext mt-2"},[_vm._v(_vm._s(item.Name))]),_c('v-spacer'),(item.inUse == false)?_c('v-chip',{staticClass:"align-center mt-1",attrs:{"outlined":"","small":"","color":"orange lighten-1","label":""}},[_vm._v("Unused")]):_vm._e(),_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-streatch",attrs:{"icon":"","size":"small"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"color":"foreground","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.networkDetails(item.Id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("View")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.selectedNetwork = item;
                  _vm.deleteDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]}},{key:"item.Project",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"projectcell"},[(item.Project)?_c('span',{staticClass:"d-inline-block text-truncate idtext"},[_vm._v(" "+_vm._s(item.Project || "-")+" ")]):_vm._e()])]}},{key:"item.Id",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"idcell"},[_c('span',{staticClass:"d-inline-block text-truncate idtext"},[_vm._v(" "+_vm._s(item.Id)+" ")])])]}},{key:"item.Driver",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"idcell"},[_c('span',{staticClass:"d-inline-block text-truncate idtext"},[_vm._v(" "+_vm._s(item.Driver)+" ")])])]}},{key:"item.Created",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate flex-grow-1 flex-shrink-0"},[_vm._v(_vm._s(_vm._f("formatDate")(item.Created)))])]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" No Networks available. ")])])],2)],1),(_vm.selectedNetwork)?_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Delete the network? ")]),_c('v-card-text',[_vm._v(" Are you sure you want to permanently delete the network?"),_c('br'),_vm._v(" This action cannot be revoked. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.deleteNetwork(_vm.selectedNetwork.Id);
            _vm.deleteDialog = false;}}},[_vm._v(" Delete ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }