var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-12 pb-8",attrs:{"color":"foreground"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Server Template Variables")])],1),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('transition-group',{attrs:{"name":"slide","enter-active-class":"animated fadeInLeft fast-anim","leave-active-class":"animated fadeOutLeft fast-anim"}},_vm._l((_vm.form.templateVariables),function(item,index){return _c('v-row',{key:index},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Variable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Variable","error-messages":errors,"success":valid,"required":""},model:{value:(item['variable']),callback:function ($$v) {_vm.$set(item, 'variable', $$v)},expression:"item['variable']"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Replacement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Replacement","error-messages":errors,"success":valid,"required":""},model:{value:(item['replacement']),callback:function ($$v) {_vm.$set(item, 'replacement', $$v)},expression:"item['replacement']"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"icon":""},on:{"click":function($event){return _vm.removeTemplateVariables(index)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)],1)}),1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"align-self-center",attrs:{"icon":""},on:{"click":_vm.addTemplateVariables}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.submitFormData()}}},[_vm._v("Save")])],1)]}}])})],1),_c('v-snackbar',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.saved = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.saved),callback:function ($$v) {_vm.saved=$$v},expression:"saved"}},[_vm._v(" Saved ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }