var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"elevation-12 pb-8",attrs:{"color":"foreground"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Change Password")])],1),_c('v-card-text',[_vm._v(" You can also change just your email here (or both your email and password). "),_c('v-form',{nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSubmit()}}},[_c('ValidationProvider',{attrs:{"name":"username","rules":"required|email","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Password","error-messages":errors,"success":valid,"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"required":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"confirm","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Confirm Password","error-messages":errors,"success":valid,"type":_vm.show2 ? 'text' : 'password',"append-icon":_vm.show2 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})]}}],null,true)}),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Change User Info")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }