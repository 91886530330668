var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"networks-list component",staticStyle:{"max-width":"90%"}},[_c('v-card',{attrs:{"color":"foreground"}},[_c('ValidationObserver',{ref:"general",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-fade-transition',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","bottom":""}}):_vm._e()],1),_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Create Network ")]),_c('v-card-text',[_vm._v(" Create a new Network. ")]),_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card-text',[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"mx-7",attrs:{"label":"Name *","placeholder":"yacht_network","error-messages":errors,"success":valid,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Driver","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{staticClass:"mx-7",attrs:{"label":"Driver *","placeholder":"bridge","error-messages":errors,"success":valid,"items":_vm.network_drivers},model:{value:(_vm.form.networkDriver),callback:function ($$v) {_vm.$set(_vm.form, "networkDriver", $$v)},expression:"form.networkDriver"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Network Interface","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [(_vm.form.networkDriver == 'macvlan')?_c('v-text-field',{staticClass:"mx-7",attrs:{"label":"Network Interface *","placeholder":"eth0","error-messages":errors,"success":valid},model:{value:(_vm.form.network_devices),callback:function ($$v) {_vm.$set(_vm.form, "network_devices", $$v)},expression:"form.network_devices"}}):_vm._e()]}}],null,true)}),_c('v-row',{staticClass:"mx-5"},[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Internal Only"},model:{value:(_vm.form.internal),callback:function ($$v) {_vm.$set(_vm.form, "internal", $$v)},expression:"form.internal"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Attachable"},model:{value:(_vm.form.attachable),callback:function ($$v) {_vm.$set(_vm.form, "attachable", $$v)},expression:"form.attachable"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Enable IPv6"},model:{value:(_vm.form.ipv6_enabled),callback:function ($$v) {_vm.$set(_vm.form, "ipv6_enabled", $$v)},expression:"form.ipv6_enabled"}})],1)],1),(
            _vm.form.networkDriver == 'bridge' ||
              _vm.form.networkDriver == 'macvlan' ||
              _vm.form.networkDriver == 'ipvlan'
          )?_c('div',[_c('v-card-text',[_vm._v(" IPv4 ")]),_c('v-row',{staticClass:"mx-5"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Subnet","placeholder":"10.0.200.0/24"},model:{value:(_vm.form.ipv4subnet),callback:function ($$v) {_vm.$set(_vm.form, "ipv4subnet", $$v)},expression:"form.ipv4subnet"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Gateway","placeholder":"10.0.200.1"},model:{value:(_vm.form.ipv4gateway),callback:function ($$v) {_vm.$set(_vm.form, "ipv4gateway", $$v)},expression:"form.ipv4gateway"}})],1)],1),_c('v-row',{staticClass:"mx-5"},[_c('v-col',[(_vm.form.networkDriver != 'macvlan')?_c('v-text-field',{attrs:{"label":"IP Range","placeholder":"10.0.200.0/24"},model:{value:(_vm.form.ipv4range),callback:function ($$v) {_vm.$set(_vm.form, "ipv4range", $$v)},expression:"form.ipv4range"}}):_vm._e()],1)],1)],1):_vm._e(),(
            _vm.form.networkDriver == 'bridge' || _vm.form.networkDriver == 'macvlan'
          )?_c('div',[_c('v-card-text',[_vm._v(" IPv6 ")]),_c('v-row',{staticClass:"mx-5"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Subnet","placeholder":"2001:db8::/32","disabled":!_vm.form.ipv6_enabled},model:{value:(_vm.form.ipv6subnet),callback:function ($$v) {_vm.$set(_vm.form, "ipv6subnet", $$v)},expression:"form.ipv6subnet"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Gateway","placeholder":"2001:db8::1","disabled":!_vm.form.ipv6_enabled},model:{value:(_vm.form.ipv6gateway),callback:function ($$v) {_vm.$set(_vm.form, "ipv6gateway", $$v)},expression:"form.ipv6gateway"}})],1)],1),_c('v-row',{staticClass:"mx-5"},[_c('v-col',[(_vm.form.networkDriver != 'macvlan')?_c('v-text-field',{attrs:{"label":"IP Range","placeholder":"2001:db8::1","disabled":!_vm.form.ipv6_enabled},model:{value:(_vm.form.ipv6range),callback:function ($$v) {_vm.$set(_vm.form, "ipv6range", $$v)},expression:"form.ipv6range"}}):_vm._e()],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":invalid},on:{"click":function($event){_vm.submit();
            _vm.createDialog = false;}}},[_vm._v(" Create ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }