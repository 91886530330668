var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templates-list component"},[_c('v-card',{attrs:{"color":"foreground"}},[_c('v-fade-transition',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","bottom":""}}):_vm._e()],1),_c('v-card-title',{staticClass:"primary font-weight-bold"},[_vm._v(" Templates "),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","to":"/templates/new"}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"foreground",attrs:{"headers":_vm.headers,"items":_vm.templates,"items-per-page":25,"footer-props":{
        'items-per-page-options': [15, 25, 50, -1]
      },"search":_vm.search},on:{"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"namecell"},[_c('span',{staticClass:"nametext"},[_vm._v(_vm._s(item.title))]),_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","size":"small"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"color":"foreground","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.templateDetails(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updateTemplate(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-update")])],1),_c('v-list-item-title',[_vm._v("Update")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.selectedTemplate = item;
                  _vm.deleteDialog = true;}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.updated_at)))])]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v(" No templates available. "),_c('a',{attrs:{"href":"/#/templates/new"}},[_vm._v("Add")]),_vm._v(" one to view information and launch apps from here. ")])])],2)],1),(_vm.selectedTemplate)?_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Delete the template? ")]),_c('v-card-text',[_vm._v(" Are you sure you want to permanently delete the template?"),_c('br'),_vm._v(" This action cannot be revoked. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.deleteTemplate(_vm.selectedTemplate.id);
            _vm.deleteDialog = false;}}},[_vm._v(" Delete ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }