var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex mx-auto page",attrs:{"color":"foreground"}},[_c('v-container',{staticClass:"component",attrs:{"fluid":""}},[_c('div',[_c('v-tabs',{staticClass:"mb-3",attrs:{"background-color":"tabs","mobile-breakpoint":"sm"},model:{value:(_vm.AppTab),callback:function ($$v) {_vm.AppTab=$$v},expression:"AppTab"}},[_c('v-tab',{staticClass:"text-left",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-arrow-left-bold-outline")]),_vm._v(" Back ")],1),_c('v-tab',{staticClass:"text-left"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-information-outline")]),_vm._v("Info ")],1),_c('v-tab',{staticClass:"text-left"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-view-list-outline")]),_vm._v("Processes ")],1),_c('v-tab',{staticClass:"text-left"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-book-open-outline")]),_vm._v("Logs ")],1),_c('v-tab',{staticClass:"text-left"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":""}},[_vm._v("mdi-gauge")]),_vm._v("Stats ")],1)],1),_c('v-fade-transition',[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","bottom":""}}):_vm._e()],1)],1),_c('v-card',{staticClass:"pb-3",attrs:{"color":"foreground","tile":""}},[_c('v-row',[_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"xs":"12","sm":"12","md":"6"}},[_c('v-card',{class:{
              'mx-4 primary': _vm.$vuetify.breakpoint.smAndDown,
              'ml-4 primary flex-shrink-1 flex-grow-0':
                _vm.$vuetify.breakpoint.mdAndUp
            }},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.app.name)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1 hidden-sm-and-down",attrs:{"size":"x-small","color":"secondary","href":("/api/apps/" + (_vm.app.name) + "/support"),"target":"_blank","download":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("Help")]),_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)]}}])},[_c('span',[_vm._v("Download Support Bundle")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1 hidden-sm-and-down",attrs:{"size":"x-small","color":"secondary"},on:{"click":function($event){return _vm.editClick({ Name: _vm.app.name })}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("Edit")]),_c('v-icon',[_vm._v("mdi-file-document-edit-outline")])],1)]}}])},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"size":"x-small","color":"secondary"},on:{"click":function($event){return _vm.refresh()}}},'v-btn',{ attrs: attrs },false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-menu',{staticClass:"hidden-md-and-up",attrs:{"close-on-click":"","close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-md-and-up mx-1",attrs:{"size":"small","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{staticClass:"hidden-md-and-up",attrs:{"color":"foreground","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editClick({ Name: _vm.app.name })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-document-edit-outline")])],1),_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"href":("/api/apps/" + (_vm.app.name) + "/support"),"target":"_blank","color":"primary","download":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1),_c('v-list-item-title',[_vm._v("Help")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'start' });
                      _vm.readAppLogs(_vm.app.name);
                      _vm.readAppStats(_vm.app.name);}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-play")])],1),_c('v-list-item-title',[_vm._v("Start")])],1),_c('v-list-item',{on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'stop' });
                      _vm.closeLogs();
                      _vm.closeStats();}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-stop")])],1),_c('v-list-item-title',[_vm._v("Stop")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.AppAction({ Name: _vm.app.name, Action: 'restart' })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-list-item-title',[_vm._v("Restart")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'kill' });
                      _vm.closeLogs();
                      _vm.closeStats();}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-fire")])],1),_c('v-list-item-title',[_vm._v("Kill")])],1),_c('v-list-item',{on:{"click":function($event){_vm.removeDialog = true}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Remove")])],1)],1)],1),(_vm.app)?_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.removeDialog),callback:function ($$v) {_vm.removeDialog=$$v},expression:"removeDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",staticStyle:{"word-break":"break-all"}},[_vm._v(" Remove "+_vm._s(_vm.app.name)+"? ")]),_c('v-card-text',[_vm._v(" Are you sure you want to permanently delete the template?"),_c('br'),_vm._v(" This action cannot be revoked. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.removeDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'remove' });
                        _vm.removeDialog = false;
                        _vm.postRemove();}}},[_vm._v(" Delete ")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-spacer',{staticClass:"hidden-sm-and-down"}),_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"sm":"12","md":"6"}},[_c('v-card',{class:{
              'mx-4 primary': _vm.$vuetify.breakpoint.smAndDown,
              'mr-4 primary': _vm.$vuetify.breakpoint.mdAndUp
            }},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'start' });
                      _vm.readAppLogs(_vm.app.name);
                      _vm.readAppStats(_vm.app.name);}}},'v-btn',{ attrs: attrs },false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("start")]),_c('v-icon',[_vm._v("mdi-play")])],1)]}}])},[_c('span',[_vm._v("Start")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'stop' });
                      _vm.closeLogs();
                      _vm.closeStats();}}},'v-btn',{ attrs: attrs },false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("stop")]),_c('v-icon',[_vm._v("mdi-stop")])],1)]}}])},[_c('span',[_vm._v("Stop")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.AppAction({ Name: _vm.app.name, Action: 'restart' })}}},'v-btn',{ attrs: attrs },false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("restart")]),_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Restart")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.AppAction({ Name: _vm.app.name, Action: 'kill' });
                      _vm.closeLogs();
                      _vm.closeStats();}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("kill")]),_c('v-icon',[_vm._v("mdi-fire")])],1)]}}])},[_c('span',[_vm._v("Kill")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"secondary"},on:{"click":function($event){_vm.removeDialog = true}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"hidden-md-and-down"},[_vm._v("remove")]),_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Remove")])])],1)],1)],1)],1),_c('transition',{attrs:{"name":"slide","enter-active-class":"animated slideInRight delay","leave-active-class":"animated slideOutRight","mode":"out-in"}},[_c('v-tabs-items',{staticClass:"mt-3",attrs:{"touchless":""},model:{value:(_vm.AppTab),callback:function ($$v) {_vm.AppTab=$$v},expression:"AppTab"}},[_c('v-tab-item'),_c('v-tab-item',[_c('Content',{attrs:{"app":_vm.app}})],1),_c('v-tab-item',[_c('Processes',{attrs:{"app":_vm.app,"processes":_vm.processes}})],1),_c('v-tab-item',[_c('Logs',{attrs:{"app":_vm.app,"logs":_vm.logs}})],1),_c('v-tab-item',[_c('Stats',{attrs:{"app":_vm.app,"stats":_vm.stats}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }